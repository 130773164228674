<script>
import axios from "axios";
import {blackTheme} from "@/views/pages/push/pushmsg/modals/imageEditorTheme";
import {ImageEditor} from '@toast-ui/vue-image-editor'

export default {
  name: "pushImageEditor",

  components: {
    "tui-image-editor": ImageEditor,
  },

  data() {
    return {
      defaultImagesUrl: [],
      backgroundImagesUrl: [],
      selectImageUrl: 'https://cdn1.critalk.com/static/images/no_image.png',
      imageEditorOptions: {
        includeUI: {
          loadImage: {
            path: this.selectImageUrl,
            name: 'img',
          },
          theme: blackTheme,
          menu: [
            'resize',
            //'crop',
            'flip',
            //'rotate',
            'draw',
            'shape',
            'icon',
            'text',
            'mask',
            'filter'
          ],
          initMenu: 'text',
          menuBarPosition: 'right',
          uiSize: {
            width: '100%',
            height: '100%'
          },
        },
        cssMaxWidth: 400,
        cssMaxHeight: 400,
      },
    }
  },

  mounted() {
    this.madeDefaultImageUrl()
    this.madeBackgroundImageUrl()
  },

  methods: {
    //이미지 base64로 가져오는 함수
    getImage(bvModalEvent) {
      let img = this.$refs.imageEditor.invoke('toDataURL', {
        format: 'jpeg',
      })

      var head = 'data:image/png;base64,';
      // let img = this.$refs.imageEditor.invoke('toDataURL')
      let fileSize = Math.round((((img.length - head.length) * 3 / 4) / 1000 / 1000) * 10) / 10

      if (fileSize > 2) {
        console.log('이야 너무 크다')
        this.$bvModal.show('resizeGuide')
        bvModalEvent.preventDefault();

        return
      }

      this.selectImageUrl = img
      this.$emit('sendingImageData', this.selectImageUrl)
    },

    //imageEditor에 들어갈 기본 이미지
    madeDefaultImageUrl() {
      /* let fileName = [
         "57.jpg",
         "coupon1.png",
         "coupon2.png",
         "coupon3.png",
         "event_1.png",
         "event_10.png",
         "event_11.png",
         "event_12.png",
         "event_13.png",
         "event_14.png",
         "event_15.png",
         "event_16.png",
         "event_17.png",
         "event_18.png",
         "event_19.png",
         "event_2.png",
         "event_20.png",
         "event_21.png",
         "event_22.png",
         "event_23.png",
         "event_24.png",
         "event_25.png",
         "event_26.png",
         "event_27.png",
         "event_28.png",
         "event_29.png",
         "event_30.png",
         "event_31.png",
         "event_32.png",
         "event_33.png",
         "event_34.png",
         "event_35.png",
         "event_36.png",
         "event_37.png",
         "event_38.png",
         "event_39.jpg",
         "event_4.png",
         "event_40.jpg",
         "event_41.jpg",
         "event_42.jpg",
         "event_43.jpg",
         "event_44.jpg",
         "event_45.jpg",
         "event_46.jpg",
         "event_47.jpg",
         "event_48.jpg",
         "event_49.jpg",
         "event_5.png",
         "event_50.jpg",
         "event_51.jpg",
         "event_52.jpg",
         "event_53.jpg",
         "event_54.jpg",
         "event_55.jpg",
         "event_56.jpg",
         "event_6.png",
         "event_7.png",
         "event_8.png",
         "event_9.png",
         "event_64.jpg",
         "event_65.jpg",
         "event_66.jpg",
         "event_67.jpg",
         "event_68.jpg",
         "event_69.jpg",
         "event_70.jpg",
         "event_71.jpg",
         "event_72.jpg",
         "event_73.jpg",
         "event_74.jpg",
         "event_75.jpg",
         "event_76.jpg",
         "event_77.jpg",
         "event_78.jpg",
         "event_79.jpg",
         "event_80.jpg",
         "event_81.jpg",
         "event_82.jpg",
         "event_83.jpg",
         "event_84.jpg",
         "event_85.jpg",
         "event_86.jpg",
         "event_87.jpg",
         "event_88.jpg",
         "event_89.jpg",
         "event_90.jpg",
         "event_91.jpg",
         "event_92.jpg",
         "event_93.jpg",
         "event_94.jpg",
         "event_95.jpg",
         "event_96.jpg",
         "event_97.jpg",
         "event_98.jpg",
         "event_99.jpg",
         "event_100.jpg",
         "event_101.jpg",
         "event_102.jpg",
         "event_103.jpg",
         "event_104.jpg",
         "event_105.jpg",
         "event_106.jpg",
         "event_107.jpg",
         "event_108.jpg",
         "event_109.jpg",
         "event_110.jpg",
         "event_111.jpg",
         "event_112.jpg",
         "event_113.jpg",
         "event_114.jpg",
         "event_115.jpg",
         "event_116.jpg",
         "event_117.jpg",
         "event_118.jpg",
         "event_119.jpg",
         "event_120.jpg",
         "event_121.jpg",
         "event_122.jpg",
         "event_123.jpg",
         "event_124.jpg",
         "event_125.jpg",
         "event_126.jpg",
         "event_127.jpg",
         "event_128.jpg",
         "event_129.jpg",
         "event_130.jpg",
         "event_131.jpg",
         "event_132.jpg",
         "event_133.jpg",
         "event_134.jpg",
         "event_135.jpg",
         "event_136.jpg",
         "event_137.jpg",
         "event_138.jpg",
         "event_139.jpg",
         "event_140.jpg",
         "event_141.jpg",
         "event_142.jpg",
         "event_143.jpg",
         "event_144.jpg",
         "event_145.jpg",
         "event_146.jpg",
         "event_147.jpg",
         "event_148.jpg",
         "event_149.jpg",
         "event_150.jpg",
         "event_151.jpg",
         "event_152.jpg",
         "event_153.jpg",
         "event_154.jpg",
         "event_155.jpg",
         "event_156.jpg",
         "event_157.jpg",
         "event_158.jpg",
         "event_159.jpg",
         "event_160.jpg",
         "event_161.jpg",
         "41.jpg",
         "42.jpg",
         "43.jpg",
         "44.jpg",
         "45.jpg",
         "46.jpg",
         "47.jpg",
         "48.jpg",
         "49.jpg",
         "50.jpg",
         "51.jpg",
         "52.jpg",
         "53.jpg",
         "54.jpg",
         "55.jpg",
       ].reverse()
       let tmpUrls = []


       fileName.forEach(function (row) {
         let url = `https://adm.critalk.com/uploads/kakao_link/events/${row}`
         let tmp = {'img_path': url}
         tmpUrls.push(tmp)
       })

       this.defaultImagesUrl = tmpUrls
       console.log(this.defaultImagesUrl)*/

      // tmpUrls.push({'img':'https://cdn1.critalk.com/imgs/macltalk/bg/57.jpg'})

      let url = '/api/maclbg/list'

      axios.get(url)
          .then((res) => {
            // console.log(res.data)

            this.defaultImagesUrl = res.data
          })
          .catch(err => console.log('err', err))
    },

    //imageEditor에 들어갈 기본 배경 이미지
    madeBackgroundImageUrl() {
      let files = ["bg1.png", "bg10.png", "bg11.png", "bg12.png", "bg13.png", "bg14.png", "bg15.png", "bg16.png", "bg17.png", "bg18.png", "bg19.png", "bg2.png", "bg20.png", "bg21.png", "bg22.png", "bg23.png", "bg24.png", "bg25.png", "bg26.png", "bg27.png", "bg28.png", "bg29.png", "bg3.png", "bg4.png", "bg5.png", "bg6.png", "bg7.png", "bg8.png", "bg9.png", "bg_color_1.png", "bg_color_10.png", "bg_color_11.png", "bg_color_12.png", "bg_color_13.png", "bg_color_14.png", "bg_color_15.png", "bg_color_16.png", "bg_color_2.png", "bg_color_3.png", "bg_color_4.png", "bg_color_5.png", "bg_color_6.png", "bg_color_7.png", "bg_color_8.png", "bg_color_9.png"].reverse()
      let tmpUrls = []

      files.forEach(function (row) {
        let url = `https://dag.ssoma.kr/uploads/kakao_link/backgrounds/${row}`
        let tmp = {'img_path': url}
        tmpUrls.push(tmp)
      })

      this.backgroundImagesUrl = tmpUrls
    },

    //background image 선택 시
    selectBackgroundImage(val) {
      //this.$refs.imageEditor.invoke('toDataURL')
      if (val.includes('cdn1')) {
        val = val.replace('cdn1', 'adm')
        console.warn(val)
      }
      console.log(val)

      this.selectImageUrl = this.$refs.imageEditor.invoke('loadImageFromURL', val, 'backgroundImage')

      this.$refs.imageEditor.invoke('ui.activeMenuEvent')
    },

  }
}
</script>

<template>
  <b-modal centered id="image-edit"
           title="이미지편집"
           body-class="p-0 m-0"
           ok-title="편집된 이미지 적용"
           cancel-title="취소"
           size="xl"
           no-enforce-focus
           @ok="getImage"
  >
    <div class="modal-body" style="height: 680px;">

      <div style="display: flex">
        <div v-if="false" style="width: 13%; text-align: center"><h5>
          샘플&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h5>
        </div>
        <div style="width: 8%; text-align: center">
          <h5 style="margin-right: 28px;">배경</h5>
        </div>
      </div>

      <div class="row" style="height: 96%;">

        <div v-if="false" style="overflow-y: scroll; height: 100%; padding: 6px; width: 13%;">
          <div v-for="row in defaultImagesUrl"
               :key="row.macl_bg_img"
          >
            <div
                style="border-right:1px solid #efefef; background-size: cover; width: 100%; padding-bottom: 100%; margin-bottom: 6px;"
                :style="{'background-image':`url(${row.img_path})`}"
                @click="selectBackgroundImage(row.img_path)"
            />
          </div>
        </div>

        <div style="overflow-y: scroll; height: 100%; padding: 6px; width: 8%;">
          <div v-for="row in backgroundImagesUrl"
               :key="row.img"
               @click="selectBackgroundImage(row.img_path)"
          >
            <div
                style="border-right:1px solid #efefef; background-size: cover; width: 100%; padding-bottom: 100%; margin-bottom: 6px;"
                :style="{'background-image':`url(${row.img_path})`}"
            />
          </div>
        </div>
        <div style="height: 100%; width: 92%;">
          <tui-image-editor
              class="col-10"
              ref="imageEditor"
              id="imgEdit"
              crossorigin="anonymous"
              :include-ui="true"
              :options="imageEditorOptions"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style scoped>

</style>
